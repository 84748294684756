import React, { Component } from 'react'
import {Tabs, Tab, Badge, Row, Col, Card, Container, Image } from 'react-bootstrap';
import { Conversation } from 'twm';
import ConfirmationModal from './ConfirmationModal.js';
import { withRouter } from 'react-router-dom';

const levelMap = {
    "0" : "Newbie",
    "1" : "Beginner",
    "2" : "Intermediate",
    "3" : "Advance"
};

const userMap = {
    '78660677-c56d-4550-b545-6ef8d121575d': 'Uma',
    '8c7bcfeb-72a1-43ad-bf7b-ceec4c2e1dc8': 'Abhi',
    '73992480-678f-4f4c-947c-26d3b91ea46b': 'Swati',
    '29f7c761-9432-4bea-a493-71abf4178c68': 'Amy'
};

class ListConversations extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            conversations: []
        };
    }

    async componentDidMount() {
        const text = this.props.match.params.text;      
        this.loadConversations();
        this.setState({filter: text});
    }

    async loadConversations(filter) {
        var conversations = await Conversation.listPublished('allwithunpub');
        this.setState({conversations});
    }

    doOnConfirm(title, message, onConfirm) {
        this.setState({
            showConfirmation: true,
            confirmationTitle: title,
            confirmationMessage: message,
            onConfirmYes: onConfirm,
            onConfirmNo: () => this.resetConfirmationDlg() 
        });
    }

    resetConfirmationDlg(reload) {
        this.setState({
            showConfirmation: false,
            onConfirmYes: null
        }, () => reload && this.loadConversations());
    }

    async delete (conv) {
        this.doOnConfirm("Delete", "Conversation '" + conv.name +"' will be permanently deleted ?", 
            async () => {
                try {
                    await Conversation.delete(conv.id);
                    alert("Delete Successful");
                } catch(error) {
                    alert("Delete Failed: " + error.message);
                }
                this.resetConfirmationDlg(true);
            }
        );
    }
    
    async edit (id) {
    }

    render() {
       return <Tabs defaultActiveKey="published" id="convs-tabs">
          <Tab eventKey="published" title="Published">
            {this.renderConversations(true)}
          </Tab>
          <Tab eventKey="unapproved" title="Unapproved">
            {this.renderConversations(false)}
          </Tab>
        </Tabs>;
    }
    
    renderCard(conv) {
        return (
        <Card key={conv.id} style={{ width: '18rem', marginBottom: '1em' }}>
          <Card.Body>
            <Card.Title>  {conv.name} </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{userMap[conv.ownerId]}</Card.Subtitle>
            <Card.Text>
                <Badge variant="dark">
                    {conv.categories.toString()}
                </Badge>
                <Image style={{height:"6em", width: "6em"}} className={"float-right"} src={conv.imageUri} thumbnail/>
                <br/>
                <small>
                    {conv.description}
                </small>
                <br/>
                <Badge variant="secondary">
                    {levelMap[conv.level] || "nolevel"}
                </Badge>

            </Card.Text>
            <Card.Link href="#" onClick={() => this.props.history.push('/conversations/edit/' + conv.id)}>Edit</Card.Link>
            <Card.Link href="#" onClick={() => this.delete(conv)}>Delete</Card.Link>
         </Card.Body>
        </Card>);
    }

    hasText(c, text) {
       if (!text || text.trim().length == 0) return true;
       const searchString = c.name + c.description + c.categories.toString();
       return searchString.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) >= 0; 
    }

    renderConversations(published) {
        const { imagePicker } = this.state;
        const { conversation, text } = this.props;
        let convs = published ? this.state.conversations.filter(c => c.published) :
                               this.state.conversations.filter(c => !c.published); 
        console.log(filter);
        const filter = this.state.filter;
        if (filter) {
            convs = convs.filter(c => this.hasText(c, filter)); 
        }
        return (
            <React.Fragment>
            <Container style={{marginTop: "1em"}}>
              <Row>
                    {convs.map((element, i) => {
                        return (<Col key={"col_listconv_" + i}> { this.renderCard(element, i) } </Col>);
                    })}
              </Row>
          </Container>
          {this.state.showConfirmation ?
          <ConfirmationModal key={"Modal1"} title={this.state.confirmationTitle} 
              content={this.state.confirmationMessage}
              onYes={this.state.onConfirmYes}
              onNo={this.state.onConfirmNo}
          /> : null}
       </React.Fragment>
      );
    }
}


export default withRouter((props) => <ListConversations  {...props}/>);
