import React, { Component, useState, useEffect } from 'react'
import {Table, Row, Col, Card, Container, Image, Button } from 'react-bootstrap';
import { Users } from 'twm';
import ConfirmationModal from './ConfirmationModal.js';
import { withRouter } from 'react-router-dom';

function sortByKey(toggle, key) {
   return toggle ? (a, b) => ((a[key] < b[key]) ? -1 : 1) : (a, b) => ((a[key] > b[key]) ? -1 : 1);
}

function toCSV(users) {
   const data = users.map(u => ",," + u.email + "," + u.created + "," + u.status).join("\n");
   return "Name, Surname, Email, Created, Status\n" + data;
}

function downloadCSVFile(users){
    const element = document.createElement("a");
    const file = new Blob([toCSV(users)], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "users.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

function tableRow(user, i) {
 return <tr key={user.email + i}>
      <td>{i}</td>
      <td></td>
      <td></td>
      <td>{user.email}</td>
      <td>{(new Date(user.created)).toDateString()}</td>
      <td>{user.status}</td>
    </tr>
}

function tableView(users, setUsers, toggle, setToggle) {
    return <Table responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Surname</th>
          <th onClick={() => {
                const sorted = users.sort(sortByKey(toggle, 'email'));
                setUsers(sorted);
                setToggle(!toggle);
            }}>Email</th>
          <th onClick={() => {
                const sorted = users.sort(sortByKey(toggle, 'created'));
                console.log(sorted);
                setUsers(sorted);
                setToggle(!toggle);
            }}>Created</th>
          <th onClick={() => {
                const sorted = users.sort(sortByKey(toggle, 'status'));
                setUsers(sorted);
                setToggle(!toggle);
            }}>Status</th>
        </tr>
      </thead>
      <tbody>
       {users && users.map((u, i) => tableRow(u, i + 1))}
      </tbody>
    </Table>;
}

async function fetchUsers(setUsers) {
    setUsers(await Users.listRegistered());
}

function UsersView(props) {
    const [users, setUsers] = useState([]);
    const [toggle, setToggle] = useState(false);
    console.log(toggle);

    useEffect(() => {
        fetchUsers(setUsers);
    },[]);
    return <Container>
      <Row style={{ marginTop: '1em', marginBottom: '0.25em' }}>
         <Button variant="outline-secondary" onClick={() => downloadCSVFile(users)}>Export To CSV</Button>
      </Row>
      <Row>
      </Row>
      <Row style={{marginBottom: '2.5em'}}>
        {tableView(users, setUsers, toggle, setToggle)}
      </Row>
      </Container>
}

export default withRouter((props) => UsersView(props));
