import React, { Component, useState, useEffect } from 'react'
import _ from 'lodash';

import { useSelector, useDispatch } from 'react-redux';
import { getTopics, deleteTopic, updateTopic, createTopic } from '../store/topics';
import { createConversation, getConversations } from '../store/conversations';
import { ConversationV5 } from 'twm';

const defaultImg = "https://twm-data.s3.ap-southeast-2.amazonaws.com/smart_images_new/logo-icon-svg.svg";

function topicsToD3(topics) {
    const rootNode = {
        "id": "TWM", 
        "uniqueId": "TWM", 
        "name": "Talk With Me",
        "image": defaultImg, 
        "parents": [],
        "show": true,
        "group": 1};

    const nodes = [rootNode, ...topics];
    const nodeMap = {};
    nodes.forEach( n => {
        nodeMap[n.id] = n;
    });
    const links =  nodes.reduce((a, c) => {
        
        if (c.id == "TWM") {
            return a;
        }

        const clinks = c.parents.filter(p => !!nodes.find(n => n.id == p))
            .map(p => {
                if (p == "TWM") {
                    c.show = true;
                } else {
                    c.show = false;
                }
                return {source:p, target: c.id, show: c.show, so: nodeMap[p], to: nodeMap[c.id]};
            });
        return [...a, ...clinks];
    }, []);

    return _.cloneDeep({nodes, links});
}

function conversationsToD3(convs)  {
    const d3Convs = convs.map(c => ({
        "published": c.published,
        "id": c.id, 
        "uniqueId": c.id, 
        "name": c.name,
        "image": c.imageUri, 
        "parents": c.categories,
        "type": "conv",
        "group": 1}));
    return d3Convs;
}

export function useContent(props) {
    const topics = useSelector((state) => _.cloneDeep(state.topics.data));
    const convs = useSelector((state) => _.cloneDeep(state.conversations.data))
    const lftags = useSelector((state) => state.lftags.data)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getTopics());
    }, []);

    const addTopic = (topic) => {
        dispatch(createTopic(topic));
    };


    const addConversation = (conv) => {
        dispatch(createConversation(conv));
    };

    const d3Content = topicsToD3([...topics, ...conversationsToD3(convs)]);
    return [d3Content, addTopic, addConversation];
}

function diffLists(l1, l2, renamed) {
    const added = l2 != null ? l2.reduce((a, c) => {
        if(!l1.find(v => v.id == c.id)) {
            a.push(c);
        }
        return a;
    }, []) : [];
   
    const removed = l2 != null ? l1.reduce((a, c) => {
        if(!l2.find(v => v.id == c.id)) {
            a.push(c);
        }
        return a;
    }, []) : [];

    const updated = renamed ? l1.reduce((a, c) => {
        if(!removed.find(v => v.id == c.id)) {
            a.push(c);
        }
        return a;
    }, []) : [];

    return [added, removed, updated];
}

function compareLists(l1, l2) {
    const ids1 = l1.map(e => e.id);
    const ids2 = l2.map(e => e.id);
    return _.isEqual(ids1.sort(), ids2.sort());
}

function isTopicModified(topic, tparents, tchildren, tconversations,
    name, image, parents, children, conversations, published) {
    if (name == "" && image == ""
        && !parents && !children && !conversations) {
        //no change made
        return [false, false, false, false];
    }

  
    const renamed = !!name && (name != topic.name);
    const tModified = renamed || (!!image && (image != topic.image)) || topic.published != published;
    const parentsModified = !!parents && !compareLists(parents, tparents);
    const childrenModified = !!children &&  !compareLists(children, tchildren);
    const convsModified = !!conversations && !compareLists(conversations, tconversations);

    return [tModified, parentsModified, childrenModified || renamed, convsModified || renamed];
}

export function useTopicEditor(props) {
    const topics = useSelector((state) => state.topics.data);
    const convs = useSelector((state) => state.conversations.data)
    const topic = topics.find(t => t.id == props.match.params.id);
    const dispatch = useDispatch()
    const allTopics = _.cloneDeep(topics);
    allTopics.push({id: "TWM", name: "Talk With Me", parents: []});

    const topicConvs = !!topic ?
        convs.filter(c => !!c.categories.find(cat => cat == topic.id)) : [];
    
    const parentTopics = !!topic ?
        allTopics.filter(t => !!topic.parents.find(p => p == t.id)) : [];
    const childTopics = !!topic ?
        allTopics.filter(t => !!t.parents.find(p => p == topic.id)) : [];

    const updateTopicFn = async (name, image, parents, children, conversations, published) => {

        const [modified, 
            parentsMods,
            childrenMods, 
            convsMods] = isTopicModified(topic, parentTopics, childTopics, topicConvs,
                        name, image, parents, children, conversations, published);
        if (!modified && !parentsMods && !childrenMods && !convsMods) {
           console.log('no modifications found!'); 
           return;
        }

        const [addedTopics, 
            removedTopics,
            updatedTopics] = childrenMods ? 
            diffLists(childTopics, children, !!name && name != topic.name)
            : [[], [], []];

        const [addedConversations,
            removedConversations, 
            updatedConversations] = convsMods ? 
            diffLists(topicConvs, conversations, !!name && name != topic.name)
            : [[], [], []];

       return await dispatch(updateTopic({id: topic.id,
            uniqueId: topic.uniqueId,
            published:published || false, 
            group: topic.group,
            type: topic.type,
            created: topic.created,
            name: !!name ? name : topic.name,
            image: !!image ? image : topic.image,
            parents: !!parents ? parents: topic.parents.map(p => ({id: p})),
            addedConversations,
            removedConversations, 
            updatedConversations,
            addedTopics, 
            removedTopics,
            updatedTopics
          }));
    };
    return [_.cloneDeep(topic),
        allTopics, topicConvs, convs, parentTopics, childTopics, updateTopicFn];
}

export function useAddTopic(props) {

}
export function useConversationEditor(props) {
    const [conv, setConv] = useState(null);
    const dispatch = useDispatch()
    useEffect(() => {
        ConversationV5.get(props.match.params.id )
            .then(c => setConv(c));
    }, []);

    const refresh = () => {
       return dispatch(getConversations());
    };

    return [conv, refresh];
} 

