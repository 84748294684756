import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { User } from 'twm';


// First, create the thunk
export const signIn = createAsyncThunk(
  'users/signIn',
  async (cred, thunkAPI) => {
    const response = !!cred ? await _signIn(cred.username, cred.password) :
         await _signIn() ;
    return response;
  }
);

// First, create the thunk
export const signOut = createAsyncThunk(
  'users/signOut',
  async (thunkAPI) => {
    const response = await _signOut();
    return response;
  }
);

async function _signIn(username, password) {
    try {
        const currentSession = await User.loggedIn();
        if(!currentSession && !!username && !!password) {
            await User.login({username, password});
        }
    } catch(e) {
        return {email: '', roles: [], error: e.message}; 
    }

    const session = await User.current();
    if (session.getIn && session.getIn(['payload', 'email'])) {
        return {
            email: session.getIn(['payload', 'email']),
            roles: session.getIn(['payload', 'cognito:groups'])
        }; 
    }
    return {email: '', roles: []}; 
}

async function _signOut() {
    await User.logout();
    return {email: '', roles: []}; 
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    loggedIn: false,
    roles: []
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(signIn.fulfilled, (state, action) => {
      // Add user to the state array
      state.email = action.payload.email;
      state.roles = action.payload.roles;
      state.loggedIn = !!action.payload.email ? true : false;
      state.error = action.payload.error;
    });

    builder.addCase(signOut.fulfilled, (state, action) => {
      // Add user to the state array
      state.email = '';
      state.roles = [];
      state.loggedIn = false;
      state.error = '';
    });
  },
});

export default userSlice.reducer;
