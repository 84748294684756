import React, { Component, useState } from 'react'
import { Conversation, ConversationText } from 'twm'
import * as d3 from 'd3';
import _ from 'lodash';
import SearchImages from './SearchImages'
import Modal from 'react-modal';
import './ConversationEditor.css';
import { Badge, 
    Alert, 
    Container, 
    Row,
    Col,
    Card, 
    FormControl,
    Button, 
    ButtonGroup,
    ButtonToolbar, 
    InputGroup } from 'react-bootstrap';

import {Form, Image, Dropdown, DropdownButton } from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import { useConversationEditor } from './hooks';
import { ConversationV5 } from 'twm';
import { getActiveChunks, getActiveSentence } from './conversationTree';
const shortid = require('shortid');


export default function ChunksView({conversation, 
    activeSentence,
    updateImage, 
    updateChunkText,
    addChoice,
    removeChoice,
    setCorrect,
    removeCorrect,
    duplicateSymbol, removeSymbol}) {
    
    const  [imagePicker, setImagePicker] = useState(null);
    const chunks = getActiveChunks (conversation, activeSentence);

    const currSent = getActiveSentence(conversation, activeSentence);
    return <React.Fragment>
        {imagePicker != null ? <SearchImages 
            searchText={chunks[imagePicker].text}
            title={"Conversation Image"} 
            updatedImage={(i) => {
                if (!i) {
                    setImagePicker(null);
                    return;
                }
                updateImage(imagePicker, i);
                setImagePicker(null);
            }}/> : null }
        <Row noGutters={false}>{chunks.map((chunk, index) => {
        return <Col xs={3} key={"card_" + index}>
            <Card className={"float-right"} style={{ width: '7em', marginBottom:"0.5em"}}>
                <Card.Img key={shortid.generate()} variant="top" 
                    alt={chunk.text}
                    onClick={() => setImagePicker(index)}
                    src={'https://s3-ap-southeast-2.amazonaws.com/twm-data/smart_images_new/' + chunk.symbolId.replace(' ', '+')} />
                <Card.Body>

                 <InputGroup className="mb-3">
                    <FormControl
                      value={chunk.text}
                      onChange={e => updateChunkText(index, e.target.value)}
                      aria-describedby="basic-addon1" />
                  </InputGroup>
                    <ButtonToolbar aria-label="Toolbar with button groups" style={{marginLeft: "-0.88em"}}>
                  <ButtonGroup className="mr-2" aria-label="First group">
                      <Button variant="light" size="sm" onClick={() => duplicateSymbol(index)}>+</Button>
                      <Button variant="danger" size="sm" onClick={() => removeSymbol(index)}>X</Button>
                      {chunk.choice ?  <Button variant="primary" size="sm" onClick={() => removeChoice(index)} >C</Button> :
                              <Button variant="light" size="sm" onClick={() => addChoice(index)} >C</Button>}
                      {chunk.choice && currSent.opinion == true ?
                              (chunk.correct ?
                          <Button variant="primary" size="sm"
                              onClick={() => removeCorrect(index)} >A</Button> :
                          <Button variant="light" size="sm"
                              onClick={() => setCorrect(index)} >A</Button>) : null}
 
                  </ButtonGroup>
                </ButtonToolbar>
              </Card.Body>
          </Card>
      </Col> ;
    })}</Row>
   </React.Fragment>
}

