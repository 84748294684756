import { configureStore } from '@reduxjs/toolkit';
import userReducer, { signIn } from './user';
import topicsReducer, { getTopics } from './topics';
import lftagsReducer, { getLFTags } from './lftags';
import conversationsReducer, { getConversations } from './conversations';

const store = configureStore({
  reducer: {
      user: userReducer,
      topics: topicsReducer,
      lftags: lftagsReducer,
      conversations: conversationsReducer 
  },
});

store.dispatch(signIn());
store.dispatch(getTopics());
store.dispatch(getLFTags());
store.dispatch(getConversations());


export default store;
