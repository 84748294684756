import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { Topics } from 'twm';


export const createTopic = createAsyncThunk(
  'topics/create',
  async (payload, thunkAPI) => {
    console.log(payload);
    const response = await Topics.create(payload);
    return response;
  }
);

export const getTopics = createAsyncThunk(
  'topics/get',
  async (payload, thunkAPI) => {
    const response = await Topics.get();
    return response;
  }
);


export const deleteTopic = createAsyncThunk(
  'topics/delete',
  async (payload, thunkAPI) => {
    const response = await Topics.delete(payload);
    return response;
  }
);


export const updateTopic = createAsyncThunk(
  'topics/update',
  async (payload, thunkAPI) => {
    console.log('update:', payload);
    let response = await Topics.update(payload);
    if (response.error) {
        throw response.error;
    }
    response = await Topics.get();
    return response;
  }
);


export const topicsSlice = createSlice({
  name: 'topics',
  initialState: {
    data: []
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(updateTopic.fulfilled, (state, action) => {
      // Add user to the state array
      state.data = action.payload;
        console.log("==============================================");
        console.log(action.payload.map(t => ({name:t.name, published: t.published})));
    });

    builder.addCase(getTopics.fulfilled, (state, action) => {
      // Add user to the state array
      state.data = action.payload;
    });

    builder.addCase(createTopic.fulfilled, (state, action) => {
      // Add user to the state array
      state.data = action.payload;
    });

    builder.addCase(deleteTopic.fulfilled, (state, action) => {
      // Add user to the state array
      state.topics = action.payload;
    });
  },
});

export default topicsSlice.reducer;
