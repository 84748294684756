import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ConversationV5 } from 'twm';


export const createConversation = createAsyncThunk(
  'conversation/create',
  async (payload, thunkAPI) => {
    console.log('create conv:', payload)
    let response = 
          await ConversationV5.create(
              payload.name, 
              payload.description, 
              payload.text, 
              payload.imageUri, 
              payload.topics, 
              payload.lftags);
    console.log(response);
    response = await ConversationV5.list();
    return response;
  }
);

export const getConversations = createAsyncThunk(
  'conversation/get',
  async (payload, thunkAPI) => {
    const response = await ConversationV5.list();
    return response;
  }
);


export const deleteConversation = createAsyncThunk(
  'conversation/delete',
  async (payload, thunkAPI) => {
    let response = await ConversationV5.delete(payload);
    response = await ConversationV5.list();
    return response;
  }
);


export const updateConversation = createAsyncThunk(
  'conversation/update',
  async (payload, thunkAPI) => {
    let response = await ConversationV5.update(payload);
    response = await ConversationV5.list();
    return response;
  }
);


export const conversationSlice = createSlice({
  name: 'conversations',
  initialState: {
    data: []
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(createConversation.fulfilled, (state, action) => {
      // Add user to the state array
      state.data = action.payload;
    });

    builder.addCase(getConversations.fulfilled, (state, action) => {
      // Add user to the state array
      state.data = action.payload;
    });

    builder.addCase(deleteConversation.fulfilled, (state, action) => {
      // Add user to the state array
      state.topics = action.payload;
    });
  },
});

export default conversationSlice.reducer;
