import React, { Component } from 'react';
import Nav from './components/Nav';
import Login from './components/Login';
import ConversationEditor from './components/ConversationEditor';
import Content from './components/Content';
import TopicEditor from './components/TopicEditor';
import ListConversations from './components/ListConversations';
import UsersView from './components/UsersView';
import { useSelector, useDispatch } from 'react-redux'
import { signIn, signOut } from './store/user'
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';


export default function App(props) {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    if (!user.loggedIn) {
        return (<Login onSignIn={(u, r) => dispatch(signIn(u, r))}/>);
    }
    
    return (
        <BrowserRouter>
            <Nav roles={user.roles} user={user.email} 
                    onSignOut={() => dispatch(signOut())}/>
            <Route exact path="/" 
                    render={(props) => <Content {...props} roles={user.roles} />}/>
            <Route exact path="/topics/:id" 
                    render={(props) => <TopicEditor {...props} roles={user.roles} />}/>
            <Route exact path="/conversations/:id" 
                    render={(props) => <ConversationEditor {...props} 
                    roles={user.roles} />}/>
            <Route exact path="/conversations/search/:text" component={ListConversations}/>
            <Route exact path="/conversations/search/" component={ListConversations}/>
            <Route exact path="/users" component={UsersView}/>
        </BrowserRouter>
  );
}
