import React, {useState} from 'react'
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import './Nav.css';
import { withRouter } from 'react-router-dom';


function Navigation (props) {
  const [searchText, setSearchText] = useState('');
  return (
          <Navbar bg="dark" variant="dark">
            <Navbar.Brand href="#home">Talk with me admin ({props.user})</Navbar.Brand>
            <Nav className="mr-auto">
                <NavDropdown title="Pages" id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={() => props.history.push('/')}>
                        Home
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item 
                        onClick={() => props.history.push('/conversations/search')}>
                        List
                    </NavDropdown.Item>*/}
                </NavDropdown>
                {/*<Button variant="outline-light" 
                onClick={() => props.history.push('/users')}>Users</Button>*/}
            </Nav>
            <Form inline>
                {/*<FormControl type="text" placeholder="Search Conversations" className="mr-sm-2" 
               value={searchText} onChange={(event) => setSearchText(event.target.value)} />
              <Button variant="outline-info" onClick={() => props.history.push('/conversations/search/' + searchText)}>Search</Button>*/}
              <Button variant="link" onClick={() => props.onSignOut()}>logout</Button>
            </Form>
          </Navbar>
  )
}

export default withRouter((props) => <Navigation {...props}/>);
