import React, { Component, useState } from 'react'
import { Conversation, ConversationText } from 'twm'
import * as d3 from 'd3';
import _ from 'lodash';
import SearchImages from './SearchImages'
import Modal from 'react-modal';
import './ConversationEditor.css';
import { Badge, 
    Alert, 
    Container, 
    Row,
    Col,
    Card, 
    FormControl,
    Button, 
    ButtonGroup,
    ButtonToolbar, 
    InputGroup } from 'react-bootstrap';

import {Form, Image, Dropdown, DropdownButton } from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import { useConversationEditor } from './hooks';
import { ConversationV5 } from 'twm';
import { toList } from './conversationTree';
const shortid = require('shortid');


function isValidOpinion(sent) 
{
    console.log(sent);
    if (!sent.chunks) {
        return false;
    }
    return !!sent.chunks.find(c => c.choice) && (!sent.choices || sent.choices.length == 0);
}

export default function SentenceView(props) {
    const sentences = toList(props.conversation);
    const [edit, setEdit] = useState(null);
    const [editSentence, setEditSentence] = useState(null);

    return (
        <React.Fragment>
            {sentences.map((s, i) => (
            <Alert key={"alert_" + s.id} show={true} 
                variant={s.id === props.activeSentence ? "success" : "light"}>
                {s.branch ? (
                    <React.Fragment>
                    <Badge size="sm" variant="secondary">{s.branch.toString()}</Badge>
                    <br/>
                    </React.Fragment>
                ) : null}
               { (edit == null || edit !=  s.id)?
                <Alert.Link 
                    onClick={() => props.onNodeSelected(s)}>
                       {s.sentence}
                </Alert.Link> : 
                <FormControl
                      value={editSentence}
                      onChange={e => {
                         setEditSentence(e.target.value);
                      }}
                      aria-describedby="basic-addon1"
                  /> 
               }

            <div className="d-flex">
             <InputGroup>
                <InputGroup.Text>
                    <InputGroup.Checkbox aria-label="Checkbox for following text input"
                            checked={s.ok}
                            onChange={async () => { 
                                props.onNodeSelected(s); 
                            }}
                    />
                </InputGroup.Text>
                <Button size="sm" onClick={() => props.onRechunk(s)} 
                    variant="outline-secondary">Re-Chunk </Button>
                <Button size="sm"
                    onClick={() => {
                        if (edit == s.id) { 
                            s.sentence = editSentence;
                            s.ok = false;
                            props.onUpdateConversation(s, props.conversation);
                            setEditSentence(null);
                            setEdit(null);
                        } else {
                            setEdit(s.id);
                            setEditSentence(s.sentence);
                        }
                    }}
                    variant="outline-secondary">
                     { edit == s.id ? 'Done' : 'Edit' }
                </Button>

                <Button size="sm" onClick={() => props.onNodeInsert(s)} 
                    variant="outline-secondary">Insert </Button>

                 {(s.parents.length == 1 &&
                    s.children.length == 1)  && <Button size="sm" onClick={() =>  props.onNodeRemove(s)} 
                    variant="outline-secondary">Remove </Button>}
                <Button size="sm" onClick={() =>  props.onNodeRemoveAll(s)} 
                    variant="outline-secondary">Remove All</Button>

                 { isValidOpinion(s) && (s.opinion == true ? 
                     <Button size="sm" onClick={() => props.onSetOpinion(s, false)} 
                         variant="success">Single Choice</Button> :
                     <Button size="sm" onClick={() => props.onSetOpinion(s, true)} 
                         variant="outline-secondary">Single Choice</Button> )
                 }
              </InputGroup>
            </div>
            </Alert>
          ))}
        </React.Fragment>
    );
}

