import React, { Component } from 'react';
import { Symbols } from 'twm';
import { Modal, Button, InputGroup, FormControl, Row, Col, Card } from 'react-bootstrap';
import './SearchImages.css';

export default class SearchImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: null,
            images: [],
            searchClicked: false,
            noImageText: ""

        }
    }

    componentDidMount() {
        const { searchText } = this.props;
        if (searchText) {
            this.setState({
                searchText
            }, () => this.searchImages());
        }
    }

    searchImages = async () => {
        var self = this;
        var images = await Symbols.search(this.state.searchText);
        images.forEach(function (element) {
            element.imageUri = element.imageUri.includes(' ') ? element.imageUri.replace(' ', '+') : element.imageUri;
        });
        
        this.setState({ 
            images, 
            noImageText: "No images found matching the text!" 
        });
    }

    handleChange = (event) => {
        this.setState({ searchText: event.target.value});

    }

    renderCard(symbol) {
        return (
          <Card key={symbol.symbolId} style={{ width: '12em', height: '18em', marginBottom:  '1em' }}>
          <Card.Img variant="bottom" src={symbol.imageUri} onClick={() => { this.props.updatedImage(symbol) }} />
          <Card.Body>
            <Card.Text>
              <small> {symbol.symbolId} </small>
            </Card.Text>
          </Card.Body>
        </Card>);
    }

    render() {

            return (
                <Modal
                  show={true}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={() => this.props.updatedImage()}
                  scrollable={true}
                >
                  <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                          <Row>
                           <Col sm={9}>
                            <FormControl value={this.state.searchText}
                              placeholder="search symbols..."
                              aria-label="search symbols..."
                              aria-describedby="basic-addon2"
                              onChange={this.handleChange.bind(this)} />
                           </Col>
                           <Col sm={3}>
                              <Button variant="outline-secondary" 
                              onClick={this.searchImages}>Search</Button>
                           </Col>
                          </Row>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <Row>
                    {this.state.images && this.state.images.length ? this.state.images.map((element, i) => {
                        return (<Col key={element.symbolId +"_col"}> { this.renderCard(element, i) } </Col>);
                    }) : <Col key={"noimages_col"}> { this.state.noImageText } </Col>}
                    </Row>
                  </Modal.Body>
                </Modal>);
    }
}

