import React, { useState } from 'react';
import { User } from 'twm';
import './Login.css'

import { useSelector, useDispatch } from 'react-redux'
import { signIn, signOut } from '../store/user'

const submitForm = async (event, dispatch, username, password) => {
    event.preventDefault();
    try{
        dispatch(signIn({username, password}));
    } catch (error) {     
        alert("Error" + JSON.stringify(error)); 
    }
}

export default function LoginForm(props) {
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    return (
      <div>
          <div className="wrapper fadeInDown bg-img">
            <div id="formContent">
                <div><h5>TWM Admin Console</h5></div>
                {!!user.error && <div className="error">{user.error}</div>}
                <form onSubmit= {(e) => submitForm(e, dispatch, username, password)}>
                  <div>
                      <input type="text" id="login" 
                          className="fadeIn second" name="login" placeholder="login" 
                          value = {username} 
                          onChange = {(e) => setUsername(e.target.value)} /></div>
                      <input type="password" 
                             id="password" 
                             className="fadeIn third" 
                             name="password" 
                             placeholder="password" 
                             value = {password} 
                             onChange = {(e) => setPassword(e.target.value)}/>
      
                      <input type="submit" className="fadeIn fourth" value="Log In"/>
                </form>
            </div>
        </div>
    </div>);
}
