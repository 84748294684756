import React, { Component, Fragment, useState, useEffect } from 'react'
import './TopicEditor.css'
import {Button, Form,  Row, Col, Image, Dropdown, DropdownButton, InputGroup } from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import SearchImages from './SearchImages';
import { Conversation } from 'twm';
import { useTopicEditor } from './hooks';

const levelMap = {
    "1" : "L1",
    "2" : "L2",
    "3" : "L3"
};


function validateLinks(parents, children) {
    if (!parents || !parents.length) {
        return "Atleast one parent topic is required";
    }

    const parentInChildren = parents.find(p => !!children.find(c => c.id == p.id));
    if (!!parentInChildren) {
        return "Parent topic cannot be a child topic";
    }

    return "";
}

export default function TopicEditor(props) {
    const [imagePicker, setImagePicker] = useState(false);
    const [topic, allTopics, topicConversations, allConversations, 
        parentTopics, childTopics, updateTopic] = useTopicEditor(props);
    const [name, setName] = useState(() => !!topic ? topic.name : "");
    const [published, setPublished] = useState(() => !!topic ? topic.published : false);
    const [image, setImage] = useState("");
    const [parents, setParents] = useState(null);
    const [children, setChildren] = useState(null);
    const [conversations, setConversations] = useState(null);
    const [error, setError] = useState("");

    const tname = !!name ? name : (!!topic? topic.name : "");
    const timage = image || (!!topic ? topic.image : "");
    return <Fragment>
        <Form  style={{ margin: "6em"}}>
        {!!error && <div className="error">{error}</div>}
         <Row style={{ marginBottom: "2em"}}> 
            <Form.Label>Topic Name and Image</Form.Label>
            <Form.Group as={Col} sm={11} xs={12} controlId="convName">
                <Form.Control value={tname} placeholder="Enter Name..." 
                    onChange={(e) => setName(e.target.value.trim())} />
            </Form.Group>
            
            <Form.Group as={Col} sm={1} xs={12} controlId="convImage">
                <Image style={{height:"2.4em", width:"2.4em"}}
                    src={timage} onClick={event => {
                      setImagePicker(true);
                  }} thumbnail/>
            </Form.Group>
          </Row> 


            <Row style={{ marginBottom: "2em"}}> 
            <Form.Label>Parent Topics</Form.Label>
            <Form.Group as={Col} sm={12} xs={12} controlId="parentTopics">
                <Typeahead
                  id={"parent-topics-list"}
                  labelKey="name"
                  multiple={true}
                  selected={!!parents ? parents : parentTopics}
                  options={allTopics.filter(t => t.id != topic.id)}
                  placeholder="Parent Topics..."
                  onChange={(ptopics) => {
                      const tChildren = !!children ? children : childTopics;
                      const perror = validateLinks(ptopics, tChildren)
                      setError(perror);
                      !perror && setParents(ptopics);
                  }} />
          </Form.Group>

          </Row>
        <Row style={{ marginBottom: "2em"}}> 
            <Form.Label>Children Topics</Form.Label>
            <Form.Group as={Col} sm={12} xs={12} controlId="childTopics">
                <Typeahead
                  id={"child-topics-list"}
                  labelKey="name"
                  multiple={true}
                  selected={!!children ? children : childTopics}
                  options={allTopics.filter(t => t.id != topic.id)}
                  placeholder="Children topics..."
                  onChange={(ctopics) => {
                      const tParents = !!parents ? parents : parentTopics;
                      const perror = validateLinks(tParents, ctopics)
                      setError(perror);
                      !perror && setChildren(ctopics);
                }} />
          </Form.Group>
           </Row>

      <Row style={{ marginBottom: "2em"}}> 
            <Form.Label>Conversations</Form.Label>
            <Form.Group as={Col} sm={12} xs={12} controlId="parentTopics">
                <Typeahead
                  id={"conversation-list"}
                  labelKey="name"
                  multiple={true}
                  selected={!!conversations ? conversations  : topicConversations}
                  options={allConversations}
                  placeholder="Topic conversations..."
                  onChange={(convs) => {
                    setConversations(convs);
                  }} />
          </Form.Group>
           </Row>
        
         <Row style={{ marginBottom: "2em"}}> 
           <Col sm={11} xs={12}>
              <Form.Check
                    type={"checkbox"}
                    label={"published"}
                    id={"pub-check"}
                    checked={published}
                    onChange={() => {
                        setPublished(!published);
                    }}
                    
                  />
            </Col>
           <Col sm={1} xs={12}>
               <Button 
                   disabled={false}
                   variant="primary"
                   onClick={() =>{
                       updateTopic(name, image,
                                   parents, 
                                   children, 
                                   conversations, published)
                       props.history.push('/');
                   }}>
                   Done 
               </Button>
            </Col>
         </Row> 
      </Form>
      {imagePicker ? <SearchImages title={"Conversation Image"} 
                searchText={tname}
                updatedImage={(i) => {
                    setImage(i.imageUri);
                    setImagePicker(false);
                }}/> : null }
  </Fragment>;
}

