import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ConversationV5 } from 'twm';


// First, create the thunk
export const getLFTags = createAsyncThunk(
  'lftags/get',
  async (payload, thunkAPI) => {
    const response = await ConversationV5.getLFTags();
    return response;
  }
);

export const lftagsSlice = createSlice({
  name: 'lftags',
  initialState: {
      data:[]
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getLFTags.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default lftagsSlice.reducer;
