import React, { Component, useState, useEffect } from 'react'
import { Conversation, ConversationText } from 'twm'
import './Content.css';
import * as d3 from 'd3';
import _ from 'lodash';
import AddContent from './AddContent';
import Modal from 'react-modal'
import { Badge, Alert, Container, Row, Col, Card, FormControl, Button, ButtonGroup, ButtonToolbar, InputGroup } from 'react-bootstrap';
import { useContent } from './hooks';
const shortid = require('shortid');

var svg;
// svg objects
var link, node;
   // the data - an object with nodes and links
var graph;

var width = 600;
var height = 600;


const forceProperties = {
    center: {
        x: 0.5,
        y: 0.5
    },
    charge: {
        enabled: true,
        strength: -30,
        distanceMin: 1,
        distanceMax: 2000
    },
    collide: {
        enabled: true,
        strength: .7,
        iterations: 1,
        radius: 5
    },
    forceX: {
        enabled: false,
        strength: .1,
        x: .5
    },
    forceY: {
        enabled: false,
        strength: .1,
        y: .5
    },
    link: {
        enabled: true,
        distance: 410,
        iterations: 1
    }
};

var simulation;
var initialized = false;
var his = null;
function initgraph(elt, data) {
    if (!data) {
        return;
    }

    simulation = d3.forceSimulation()
    .alphaTarget(0.8)
    .alphaMin(0.001)
    .force("charge", d3.forceManyBody().strength(-200))
    .force('collide', d3.forceCollide(function (d: any) { return 30; }).iterations(16))
    .force("center", d3.forceCenter(width/2, height/2).strength(1))
    .force("link", d3.forceLink().id(function(d) {return d.id;})
        .distance(forceProperties.link.distance)
        .iterations(forceProperties.link.iterations));

    simulation.stop();

    svg = d3.select(elt);
    const transform = JSON.parse(localStorage.getItem("twmViewZoom"));
    //d3.behaviour.zoom().scale(transform.k).translate(transform.x, transform.y);
    const zoom = d3.zoom()
      .on('zoom', (e) => {
            d3.select("g.nodes").attr('transform', e.transform);
            d3.select("g.links").attr('transform', e.transform);
            localStorage.setItem( "twmViewZoom", JSON.stringify(e.transform)); 
    });
    svg.call(zoom);
   !!transform && svg.transition().duration(2500).call(
      zoom.transform,
      d3.zoomIdentity
       .scale(transform.k)
       .translate(transform.x, transform.y)
    );

    width = svg.node().getBoundingClientRect().width;
    height = svg.node().getBoundingClientRect().height;

    const root = data.nodes.find(d => d.id == "TWM");
    root.fixed = true;
    root.x = width/2;
    root.y = height/2;
    restart(null, data);
  // update size-related forces
    d3.select(window).on("resize", function() {
        width = +svg.node().getBoundingClientRect().width;
        height = +svg.node().getBoundingClientRect().height;
    });
}

function postionFromSaved() {
    const fx = JSON.parse(localStorage.getItem("twmContentNodes"));
    if (fx && graph.nodes.length > 1) {
        for (const f of graph.nodes) {
          if (f && fx[f.uniqueId]) {
            f.fx = fx[f.uniqueId].fx;
            f.fy = fx[f.uniqueId].fy;
          }
        } 
    }
}

function restart(history, data) {
    graph = data; 

    initializeDisplay(history);
    postionFromSaved();
    simulation.nodes(graph.nodes);
    simulation.force("link").links(graph.links);
    simulation.on("tick", ticked);
    simulation.alpha(1).restart();
}

// add forces to the simulation

//////////// DISPLAY ////////////

// generate the svg objects and force simulation
function initializeDisplay(history) {
  // set the data and properties of link lines
  const showMap = JSON.parse(localStorage.getItem("twmContentNodesShow")) || {};
  graph.nodes.forEach(n => {
      if (n.type == "conv") return;
      if (showMap[n.uniqueId] == true) {
          n.show = true;
      } else if (showMap[n.uniqueId] != false) {
          showMap[n.uniqueId] = n.show;
      }
  });

  graph.links.forEach((l, i) => {
      if (l.to.type == "conv") return;
      if (showMap[l.so.uniqueId + "_" + l.to.uniqueId] == true) {
          l.show = true;
      } else if (showMap[l.so.uniqueId + "_" + l.to.uniqueId] != false) {
          showMap[l.so.uniqueId + "_" + l.to.uniqueId] = l.show;
      }

  });

    //console.log(Object.keys(showMap).filter(v => showMap[v]).map(v => v));

  link = svg.select('g.links')
    .selectAll("line")
    .data(graph.links, d => [d.source, d.target]);

  link.exit().remove();
  const linkEnter = link.enter().append("line").
        attr("marker-end", "url(#triangle)")
        .style("visibility", d => {
            return d.show ? "visible": "hidden"
        });

  link.merge(linkEnter);

  // set the data and properties of node circles
  node = svg.select('g.nodes')
    .selectAll("g")
    .data(graph.nodes, d => d.id + d.published + d.name)
  
  node.exit().remove(); 
  
  const nodeEnter = node.enter().append('g')
        .call(d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended));
 
  nodeEnter.style("visibility", n => n.show ? "visible" : "hidden");

  // node tooltip
  nodeEnter.filter(d => true)
    .append('rect')
    .attr('x', -10)
    .attr('y', -20)
    .attr('height', 70)
    .attr('width', 60)
    .attr('fill', (d) => {   
            return d.published == true ? 'white' : 'pink'
        })
    .attr('stroke', (d) => d.published == true ? 'green' : 'red');

  nodeEnter.append("text").
        text(function(d) { return d.name; }).
        style("fill", "#000");

  nodeEnter.append('image')
    .attr('xlink:href',function(d,i){
      return d.image;
    }).attr('height',45)
    .attr('width',45)
    .filter(n => n.type != "conv")
    .on("click", (e, d)=> onExpandCollapse(e, d, node, link))

  nodeEnter.filter(n => n.type == "conv")
    .on("click", (e, d) => {
        if (d.type != "conv") {
            his && his.push('/topics/' + d.id);
        } else {
            his && his.push('/conversations/' + d.id);
        }
     });
    
  nodeEnter.filter(n => n.type != "conv")
    .append('circle')
    .attr('cx', 55)
    .attr('cy', -20)
    .attr('fill', 'blue')
    .attr('stroke', 'blue')
    .attr('r', 9)
    .on("click", (e, d) => {
        if (d.type != "conv") {
            his && his.push('/topics/' + d.id);
        } else {
            his && his.push('/conversations/' + d.id);
        }
     });

  nodeEnter.filter( d => d.type != "conv")
    .append('circle')
    .attr('cx', 55)
    .attr('cy', 50)
    .attr('fill', 'teal')
    .attr('stroke', 'teal')
    .attr('r', 9)
    .on("click", onAddTopic);

  nodeEnter.append("title")
      .text(function(d) { return d.name; });
  node.merge(nodeEnter);

  node.attr("r", forceProperties.collide.radius)
        .attr("stroke", "black")
        .attr("stroke-width", 1);

  link.attr("stroke-width", 2)
        .attr("opacity", 1);
}

// update the display positions after each simulation tick
function ticked() {

 d3.selectAll("g.links line")
    .attr("x1", d => d.source.x)
    .attr("x2", d => d.target.x)
    .attr("y1", d => d.source.y)
    .attr("y2", d => d.target.y)
 
  //position nodes
  d3.selectAll("g.nodes g")
    .attr('transform', (d, id) => {
        return  `translate(${d.x}, ${d.y})`
    });
  
  d3.select('#alpha_value').style('flex-basis', (simulation.alpha()*100) + '%');
}



//////////// UI EVENTS ////////////
var addTopicFn;

function onAddConversation(event, d) {
  if (!event.active) simulation.alphaTarget(0.8).restart();
}


// expand collapse children under the node d
function onExpandCollapse(event, d, node, link) {
  if (!!event && !event.active) simulation.alphaTarget(0.8).restart();

  const showVals = {};
  svg.select('g.links')
    .selectAll("line").filter(function (n) {
        if (!event && !n.show) {
            //skip unshown in case of recursive collapse
            return false;
        }

        const val = n.source.uniqueId == d.uniqueId; 
        if (val) { 
            n.show = !n.show;
        }

        showVals[n.source.uniqueId + "_" + n.target.uniqueId] = n.show;
        return true;
  }).style("visibility", n =>  n.show ? "visible" : "hidden");

  svg.select('g.nodes')
    .selectAll("g").filter(function (n) {
        if (!event && !n.show) {
            //skip unshown in case of recursive collapse
            return false;
        }
        const val = n.parents.find(p => p == d.id); 
        if (val) { 
            if (n.show) {
                //do the same for visible subtree
                onExpandCollapse(null, n, node, link);
            }
            n.show = !n.show;
        }
        
        showVals[n.uniqueId] = n.show;
        return val;
  }).style("visibility", n => n.show ? "visible" : "hidden");

  if (!!event) {
    svg.select('g.nodes')
    .selectAll("g").filter(function (n) {
        showVals[n.uniqueId] = n.show;
    });

    svg.select('g.links')
    .selectAll("line").filter(function (n) {
        showVals[n.source.uniqueId + "_" + n.target.uniqueId] = n.show;
    });
   
    console.log('changed:',Object.keys(showVals).filter(v => showVals[v]).map(v => v));
    localStorage.setItem( "twmContentNodesShow", JSON.stringify(showVals)); 
  }
 }



function onAddTopic(event, d) {
  if (!event.active) simulation.alphaTarget(0.8).restart();
  addTopicFn(d);
}

function dragstarted(event, d) {
  if (!event.active) simulation.alphaTarget(0.8).restart();
  d.fx = d.x;
  d.fy = d.y;
}

function dragged(event, d) {
  d.fx = event.x;
  d.fy = event.y;
}

function dragended(event, d) {
  if (!event.active) simulation.alphaTarget(0.0001);
  // save fixed locations to localStorage
  localStorage.setItem(
      "twmContentNodes",
      JSON.stringify(
        simulation
          .nodes()
          .filter(d => d.fx)
          .reduce((a, d) => {
              if (d.type == "conv") return a;
              a[d.uniqueId] = {fx: d.fx, fy: d.fy};
            return a
          }, {})
      )
  ); 
}


const useD3 = (renderChartFn, data, setShowAddTopic, dependencies) => {
    const ref = React.useRef();
    addTopicFn = setShowAddTopic;
    React.useEffect(() => {
        renderChartFn(ref.current, data);
        return () => {};
      }, dependencies);
    return ref;
};
   
export default function Content(props)  {
    const [showAddTopic, setShowAddTopic] = useState(null);
    const [content, 
        addTopic, 
        addConversation] = useContent(props);
    const ref = useD3(initgraph, content, setShowAddTopic, []);
    useEffect(() => {
        restart(props.history, content);
    },[content]);
    his = props.history;
    return (
        <Container>
          <svg 
            key={'content-view'}
            ref={ref}
            style={{
            height: 500,
            width: "100%",
            marginTop: '2em',
            marginLeft: "0px",
            borderWidth: 3,
            borderColor: 'black',
            backgroundColor: 'white'
          }}>
          <defs>
            <marker id="triangle" viewBox="0 0 5 5"
                  refX="1" refY="2.5"
                  markerUnits="strokeWidth"
                  markerWidth="5" markerHeight="5"
                  orient="auto">
              <path d="M 0 0 L 5 2.5 L 0 5 z" fill="#f0f"/>
            </marker>
          </defs>
              <rect width="100%" height="100%" 
                  fill="#fff" stroke="#636363" stroke-width="5"/>
              <g class="nodes"/>
              <g class="links"/>
          </svg>

        {showAddTopic ? <AddContent topic={showAddTopic} 
            key={new Date()}
            addConversation={(d, i, image, text, lftags) => {
                const nConv = {
                    name: i,
                    imageUri: image,
                    description: "",
                    text: text,
                    lftags,
                    topics: [d.id]
                };
                addConversation(nConv);
                setShowAddTopic(null);
            }}
            addTopic={(d, i, image) => {
                const nNode = { 
                      "name": i,
                      "image": image, 
                       "type": "topic",
                       "parents": [d.id],
                    "group": 1};
                addTopic(nNode);
                setShowAddTopic(null);
            }}/> : null }
        </Container>);
}

