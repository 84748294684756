import React, { Component, useState } from 'react';
import { Symbols } from 'twm';
import {Form, 
    Image,
    Dropdown,DropdownButton,
    Modal, Button, InputGroup, FormControl, Row, Col, Card } from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import './AddContent.css';
import SearchImages from './SearchImages';
import { useSelector, useDispatch } from 'react-redux';

//--------------------------------------------------------------------
//
//--------------------------------------------------------------------
function validateConversation(name, conversations, setError) {
    if (!!conversations.find(c => c.name.toLowerCase().trim() == 
        name.toLowerCase().trim())) {
        setError("conversation with the name already exists!");
        return false;
    }
    setError(null);
    return true;
}

//--------------------------------------------------------------------
//
//--------------------------------------------------------------------
function validateTopic(name, topics, setError) {
    if (!!topics.find(t => t.name.toLowerCase().trim() == 
        name.toLowerCase().trim())) {
        setError("topic with the name already exists!");
        return false;
    }
    setError(null);
    return true;
}


//--------------------------------------------------------------------
//
//--------------------------------------------------------------------
export default function AddTopic(props) {
    const topics = useSelector((state) => state.topics.data);
    const convs = useSelector((state) => state.conversations.data);
    const alllftags = useSelector((state) => state.lftags.data);

    const [name, setName] = useState('');
    const [imageUri, setImageUri] = useState('');
    const [imagePicker, setImagePicker] = useState(false);
    const [ctext, setCText] = useState("");
    const [lftags, setlfTags] = useState([]);

    const [show, setShow] = useState(true);
    const [error, setError] = useState("");

    return <div>
        <Modal
              show={show}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setShow(false)}
              scrollable={true} >
              <Modal.Header closeButton>
                  <Modal.Title>{props.topic.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                    {!!error && <div className="error">{error}</div>}
                    <Row key="r1">
                    <Form.Group as={Col} sm={11} xs={12} controlId="topicName">
                    <Form.Control 
                        value={name} 
                        placeholder="topic name" 
                        onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} sm={1} xs={12} controlId="topicImage">
                       <Image style={{height:"2.4em", width:"2.4em"}}
                            src={imageUri} onClick={event => {
                                setImagePicker(true) 
                          }} thumbnail/>
                    </Form.Group>
                    </Row>
                    <Row style={{marginTop: '1em'}}>
                        <Col sm={12} xs={12}>
                            <Typeahead
                              id={"lftags-list"}
                              labelKey="name"
                              multiple={true}
                              selected={lftags}
                              options={alllftags}
                              placeholder="Learning Framework Tags..."
                              onChange={(ctags) => setlfTags(ctags)} />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '1em'}}>
                          <Form.Group as={Col} controlId="convTextArea">
                              <Form.Control name={"cText"} value={ctext} 
                                  as="textarea" rows="3"  
                                placeholder="Conversation Text..." 
                                onChange={(e) => {
                                    const text = e.target.value.replace(/[\u2019]/g,'\'');
                                    setCText(text);
                                }}  />
                          </Form.Group>
                    </Row>            
                    <Row style={{marginTop: '1em'}}>
                        <Col sm={12} xs={12}>
                         <Button key="publish" 
                            className={"float-end"}
                            style={{marginLeft: '1em'}}
                            disabled={!name || !imageUri || !!ctext || !!lftags.length} 
                            onClick={() => {
                                if (validateTopic(name, topics, setError)) {
                                    props.addTopic(props.topic, name, imageUri);
                                }
                            }}
                            variant="outline-success">
                            {"Add Topic"}
                          </Button>
                          <Button key="add-conv" 
                            className="float-end"
                            disabled={!name || !imageUri || !ctext} 
                            onClick={() => { 
                                if (validateConversation(name, convs, setError)) {
                                    props.addConversation(props.topic, name, imageUri, ctext, lftags);
                                }
                            }}
                            variant="outline-success">
                            Add Conversation
                        </Button>
                       
                        </Col>
                    </Row>
                  </Form>
                  </Modal.Body>
            </Modal>
            {imagePicker ? <SearchImages
                title={"Conversation Image"} 
                searchText={name}
                updatedImage={(i) => {
                    setImagePicker(false);
                    if (!i) return;
                    setImageUri(i.imageUri);
            }}/> : null }
    </div>;
}


