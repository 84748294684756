import React, { Component, useState, useEffect } from 'react'
import { Conversation, ConversationText } from 'twm'
import * as d3 from 'd3';
import _ from 'lodash';
import SearchImages from './SearchImages'
import Modal from 'react-modal';
import './ConversationEditor.css';
import { Badge, 
    Alert, 
    Container, 
    Row,
    Col,
    Card, 
    FormControl,
    Button, 
    ButtonGroup,
    ButtonToolbar, 
    InputGroup } from 'react-bootstrap';

import {Form, Image, Dropdown, DropdownButton } from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import { useConversationEditor } from './hooks';
import { ConversationV5 } from 'twm';
import ConversationInfoView from './ConversationInfoView';
import TreeView from './TreeView';
import SentenceView from './SentenceView';
import ChunksView from './ChunksView';
import ChunksPreview from './ChunksPreview';

import {
    save,
    conversationTextToTree,
    validateSentence,
    updateOpinion,
    loadNodeChunks,
    nodeInsert,
    nodeRemove,
    nodeRemoveAll,
    duplicateSymbol,
    updateChunkText,
    conversationToTree,
    findSentence,
    updateImage,
    addChoice,
    removeSymbol,
    removeChoice,
    setCorrectChoice,
    removeCorrectChoice
} from './conversationTree';

const shortid = require('shortid');

//-------------------------------------------------------------------------
//
//-------------------------------------------------------------------------
function canPublish (conversation, treeConversation) {
        if (!conversation) {
            return false;
        }
        if (!conversation.name || conversation.name.trim().length === 0 ||
          !conversation.text || conversation.text.trim().length === 0 ||
          !conversation.categories || conversation.categories.length === 0 ||
          !conversation.lftags || conversation.lftags.length === 0 ||
          !conversation.imageUri || conversation.imageUri.length ===0 || 
          !conversation.level) {
          return false
        }
        if (!treeConversation) return false
        const nok = findSentence(treeConversation, c => {
          return !c.ok
        });
            
        return !nok
}

//-------------------------------------------------------------------------
//
//-------------------------------------------------------------------------
function renderActionBar (props, conversation, treeConversation, 
    convInfo, setConvInfo, setTreeConversation, setError, setPreview, preview, refresh, history) {
      const roles = props.roles;
      if (!roles) return null;
      return (<div>
          <Button key="save" className="float-end"
              disabled={!canPublish(convInfo, treeConversation)} onClick={async () => {
                 const ret = await save(false, convInfo, treeConversation);
                 if (ret.error) {
                      setError(ret.error);
                 } else {
                     setTreeConversation(ret.treeConversation);
                     setConvInfo(ret.conversation);
                     refresh();
                     history.push('/conversations/' + ret.conversation.id);
                 }
              }}
              variant="outline-success">Save</Button>
          {roles.includes('twm-admin') ? 
          <Button key="publish" className="float-end"
              style={{marginLeft: '1em', marginRight: '1em'}}
              disabled={!canPublish(convInfo, treeConversation)} onClick={async () => {
                 const ret = await save(true, convInfo, treeConversation);
                 if (ret.error) {
                      setError(ret.error);
                 } else {
                     setTreeConversation(ret.treeConversation);
                     setConvInfo(ret.conversation);
                     history.push('/conversations/' + ret.conversation.id);
                 }
              }}
              variant="outline-success">Publish</Button> : null }
           <Button key={"preview"}
              className="float-end"
              disabled={false}
              variant="primary"
              onClick={() => {
                setPreview(!preview);     
               }}>
              {"Preivew"}
         </Button>


        </div>
    );
}


//-------------------------------------------------------------------------
//
//-------------------------------------------------------------------------
export default function ConversationEditor(props) {
    const [conversation, refresh] = useConversationEditor(props);
    const [active, setActive] = useState(-1);
    const [error, setError] = useState(null);
    const [preview, setPreview] = useState(false);
    const [convInfo, setConvInfo] = useState(null);
    const [treeConversation, setTreeConversation] = useState(null);
    const [hideText, setHideText] = useState(false);

    useEffect(() => {
          !!conversation && setConvInfo(conversation); 
          !!conversation && !!conversation.dialogue.length &&
            setTreeConversation(
                !conversation.dialogue.length ? conversationTextToTree(conversation.text) :
                conversationToTree(conversation)
            );
    }, [conversation]);


    if (!conversation || !conversation.dialogue) {
        return <div>loading...</div>
    }
    const itreeConversation = !!treeConversation ?
        _.cloneDeep(treeConversation) : 
       ( !conversation.dialogue.length ? conversationTextToTree(conversation.text) :
           conversationToTree(conversation));

    return  <Container>
             <Row style={{marginTop: '1em'}}>
                    {renderActionBar(props, conversation, itreeConversation, convInfo, 
                  setConvInfo, setTreeConversation, setError, setPreview, preview, 
                 refresh, props.history)}
              </Row>
            <ConversationInfoView key={"load_conversation"} 
                conversation={{...conversation, ...convInfo}} 
                hideText={hideText}
                updateName={(n) => {
                        setConvInfo({...convInfo, name: n}); 
                    }
                } 

                updateText={(t) => {
                        setConvInfo({...convInfo, text: t}); 
                    }
                } 

                updateImage={(t) => {
                        setConvInfo({...convInfo, imageUri: t.trim()}); 
                    }
                } 

                updateLevel={(t) => {
                        setConvInfo({...convInfo, level: t.trim()}); 
                    }
                } 

                updateTopics={(t) => {
                        setConvInfo({...convInfo, categories: t}); 
                    }
                }

                updateLFTags={(t) => {
                        setConvInfo({...convInfo, lftags: t}); 
                    }
                }
                
                updateFinishingText={(n) => {
                        setConvInfo({...convInfo, finishingText: n}); 
                    }
                } 
            />
            <Row style={{marginTop: '1em'}}>
              <Col xs={12}>
              <Button key="save" 
                  disabled={!convInfo || !convInfo.text} onClick={() => {
                     const ret = conversationTextToTree(convInfo.text);
                     setTreeConversation(ret);
                  }}
                  variant="outline-primary">Load text</Button>
              </Col>
            </Row>

            <Row style={{margin:'1em'}}>
                  {!!error &&
                      <Button disabled={true} 
                          className="float-end"
                          size="sm" key={'error_msg'} variant={'danger'}>
                          <small>{error}</small>
                     </Button> }
              </Row>
              
          <hr/>
            {preview && <Row key={"preview"} style={{margin: '1em', marginBottom: '2em'}}>
               <ChunksPreview conversation={itreeConversation}/>
            </Row>}
            {!preview && 
            <Row style={{margin: '1em', marginBottom: '2em'}}>
              <Col xs={3} style={{height: "36rem", overflowY: "scroll"}}>
                  <TreeView conversation={itreeConversation}
                              activeSentence={active.id}
                              onNodeSelected={(d) => setActive(d)}/>

              </Col>
              <Col xs={3} style={{height: "36rem", overflowY: "scroll"}}>
                  <SentenceView conversation={itreeConversation} 
                              activeSentence={active.id}
                              onUpdateConversation={(d, c) => {
                                  const validate = validateSentence(d, c);
                                  if (validate.error) {
                                      setError(validate.error);
                                  } else {
                                      setError(null);
                                  }
                                  setTreeConversation(c);
                              }}
                              onNodeSelected={async (d) => {
                                  const ret =
                                      await loadNodeChunks(d, itreeConversation, false);
                                  if (!!ret && ret.error) {
                                      setError(ret.error);
                                  } else if (!!ret) {
                                      const validate = validateSentence(d, ret);
                                      if (validate.error) {
                                          setError(validate.error);
                                      }
                                      setTreeConversation(ret);
                                  }
                                  setActive(d);
                              }}
                              onSetOpinion= {(s, v) => {
                                  const validate = updateOpinion(s, itreeConversation, v);
                                  if (validate.error) {
                                      setError(validate.error);
                                  } else {
                                      setError(null);
                                  }
                                  s.ok = false;
                                  setTreeConversation(itreeConversation);
                                  setActive(s);
                              }}
                              onRechunk={async (d) => {
                                  const ret =
                                      await loadNodeChunks(d, itreeConversation, true);
                                  if (!!ret && ret.error) {
                                      setError(ret.error);
                                  } else if (!!ret) {
                                      const validate = validateSentence(d, ret);
                                      if (validate.error) {
                                          setError(validate.error);
                                      }
                                      setTreeConversation(ret);
                                  }
                                  setActive(d);
                              }}

                              onNodeInsert={async (d) => {
                                  const ret = await nodeInsert(d, itreeConversation);
                                  if (!!ret && ret.error) {
                                      setError(ret.error);
                                  } else if (!!ret) {
                                      setTreeConversation(ret);
                                  }
                              }}
                             onNodeRemoveAll={async (d) => {
                                  const ret = await nodeRemoveAll(d, itreeConversation);
                                  if (!!ret && ret.error) {
                                      setError(ret.error);
                                  } else if (!!ret) {
                                      setTreeConversation(ret);
                                  }
                              }}
                              onNodeRemove={async (d) => {
                                  const ret = await nodeRemove(d, itreeConversation);
                                  if (!!ret && ret.error) {
                                      setError(ret.error);
                                  } else if (!!ret) {
                                      setTreeConversation(ret);
                                  }
                              }}/>

              </Col>
              <Col style={{height: "36rem", overflowY: "scroll"}}>
                  <ChunksView conversation={itreeConversation} 
                              activeSentence={active.id}
                              updateImage={(i, d) => {
                                  updateImage(d, itreeConversation, active.id, i);
                                  setTreeConversation(itreeConversation);
                              }}

                              updateChunkText={(i, t) => {
                                  const ret = 
                                      updateChunkText(i, itreeConversation, active.id, t);
                                  if (ret.error) {
                                      setError(ret.error);
                                      setTreeConversation(itreeConversation);
                                  } else {
                                      setError(null);
                                      setTreeConversation(itreeConversation);
                                  }

                              }}
                              setCorrect={(i) => {
                                  const ret = 
                                      setCorrectChoice(i, itreeConversation, active.id);
                                  if (ret.error) {
                                      setError(ret.error);
                                      setTreeConversation(itreeConversation);
                                  } else {
                                      setError(null);
                                      setTreeConversation(itreeConversation);
                                  }
                              }}
                              removeCorrect={(i) => {
                                  const ret = 
                                      removeCorrectChoice(i, itreeConversation, active.id);
                                  if (ret.error) {
                                      setError(ret.error);
                                      setTreeConversation(itreeConversation);
                                  } else {
                                      setError(null);
                                      setTreeConversation(itreeConversation);
                                  }
                              }}

                              addChoice={(i) => {
                                  const ret = 
                                      addChoice(i, itreeConversation, active.id);
                                  if (ret.error) {
                                      setError(ret.error);
                                      setTreeConversation(itreeConversation);
                                  } else {
                                      setError(null);
                                      setTreeConversation(itreeConversation);
                                  }
                              }}
                              removeChoice={(i) => {
                                  const ret = 
                                      removeChoice(i, itreeConversation, active.id);
                                  if (ret.error) {
                                      setError(ret.error);
                                      setTreeConversation(itreeConversation);
                                  } else {
                                      setError(null);
                                      setTreeConversation(itreeConversation);
                                  }
                              }}
                              duplicateSymbol={(i) => {
                                  const ret = 
                                  duplicateSymbol(i, itreeConversation, active.id);
                                  if (ret.error) {
                                      setError(ret.error);
                                      setTreeConversation(itreeConversation);
                                  } else {
                                      setError(null);
                                      setTreeConversation(itreeConversation);
                                  }
                              }}
                              removeSymbol={(i) => {
                                  const ret = 
                                  removeSymbol(i, itreeConversation, active.id);
                                  if (ret.error) {
                                      setError(ret.error);
                                      setTreeConversation(itreeConversation);
                                  } else {
                                      setError(null);
                                      setTreeConversation(itreeConversation);
                                  }
                              }} />
              </Col>
          </Row>}
    </Container>
}

