import React, { Component, Fragment, useState } from 'react'
import {Form,  Row, Col, Image, Dropdown, DropdownButton } from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import SearchImages from './SearchImages';
import { useSelector, useDispatch } from 'react-redux';
import { ConversationV5 } from 'twm';

const levelMap = {
    "1" : "Level 1",
    "2" : "Level 2",
    "3" : "Level 3"
};

//--------------------------------------------------------------
//
//--------------------------------------------------------------
function selectedLFTags(conversation, allTags) {
       if (conversation && conversation.lftags) {
            const { lftags } = conversation;
            if (lftags) {
                const tags = allTags.filter(t => lftags.includes(t));
                return tags;
            }
       }
       return [];
}


//--------------------------------------------------------------
//
//--------------------------------------------------------------
function selectedTopics(conversation, allTopics) {
       if (conversation && conversation.categories) {
            const { categories } = conversation;
            if (categories) {
                const topics = allTopics.filter(t => categories.includes(t.id));
                return topics;
            }
       }
       return [];
}

//--------------------------------------------------------------
//
//--------------------------------------------------------------
export default function ConversationInfoView(props) {
    const topics = useSelector((state) => state.topics.data)
    const lftags = useSelector((state) => state.lftags.data)
    const  [imagePicker, setImagePicker] = useState(false);
    const  [text, setText ] = useState("");
    const conversation = props.conversation;

    return <Fragment>
        {imagePicker ? <SearchImages 
            searchText={conversation.name}
            title={"Conversation Image"} 
            updatedImage={(i) => {
                setImagePicker(false);
                if (!i) return;
                props.updateImage(i.imageUri);
            }}/> : null }
    <Form  style={{marginTop: "1em"}}>
        <Row>
            <Form.Group as={Col} sm={8} xs={12} controlId="convName">
                <Row>
                    <Form.Label>Title</Form.Label>
                </Row>
                <Form.Control value={conversation.name ? conversation.name : ""} 
                    placeholder="Enter Name..." 
                    onChange={(e) => props.updateName(e.target.value)} />

            </Form.Group>

            <Form.Group as={Col} sm={1} xs={12} controlId="convImage">
                    <Row>
                        <Form.Label>Image</Form.Label>
                    </Row>
                    <Image style={{height:"2.4em", width:"2.4em"}}
                        src={conversation.imageUri || ''} onClick={event => {
                         setImagePicker(true); 
                      }} thumbnail/>
            </Form.Group>
            
            <Form.Group as={Col} sm={3} xs={12} controlId="convImage">
                    <Row>
                        <Form.Label>Level</Form.Label>
                    </Row>
                    <DropdownButton 
                        variant="light" 
                        title={levelMap[conversation.level] || "Level"}
                        id="level-dropdown"
                        key="level-dropdown"
                        onSelect={(e) => props.updateLevel(e)}>
                         {Object.keys(levelMap)
                         .map( l => 
                             <Dropdown.Item eventKey={l}>{levelMap[l]}</Dropdown.Item>)}
                      </DropdownButton>

            </Form.Group>
        </Row>
        
        <Row style={{marginTop: '1em'}}>
            <Form.Group as={Col} sm={6} xs={12} controlId="convImage">
                    <Row>
                        <Form.Label>Topics</Form.Label>
                    </Row>
                    <Typeahead
                      id={"topics-list"}
                      labelKey="name"
                      multiple={true}
                      selected={selectedTopics(props.conversation, topics)}
                      options={topics}
                      placeholder="Topics..."
                      onChange={(ctopics) => props.updateTopics(ctopics.map(t => t.id))} />
            </Form.Group>
            <Form.Group as={Col} sm={6} xs={12} controlId="convImage">
                    <Row>
                        <Form.Label>Learning Tags</Form.Label>
                    </Row>
                    <Typeahead
                      id={"lftags-list"}
                      labelKey="name"
                      multiple={true}
                      selected={selectedLFTags(props.conversation, lftags)}
                      options={lftags}
                      placeholder="Learning Framework Tags..."
                      onChange={(ctags) => props.updateLFTags(ctags)} />
            </Form.Group>
      </Row>

      {!!props.hideText ? null :
      <Row style={{marginTop: '1em'}}>
          <Form.Group as={Col} controlId="convTextArea">
              <Form.Label>Conversation Text</Form.Label>
              <Form.Control name={"cText"} value={text.length ? text : 
                  conversation.text } as="textarea" rows="5"  
                placeholder="Conversation Text..." 
                onChange={(e) => {
                    const text = e.target.value.replace(/[\u2019]/g,'\'');
                    console.log(text);
                    props.updateText(text)
                }}  />
          </Form.Group>
      </Row>}
      <Row style={{marginTop: '1em'}}>
            <Form.Group as={Col} sm={12} xs={12} controlId="openended">
                <Row>
                    <Form.Label>End conversation text</Form.Label>
                </Row>
                <Form.Control value={conversation.finishingText ? conversation.finishingText : ""} 
                    placeholder="Enter text to be diplayed at the end of conversation..." 
                    onChange={(e) => props.updateFinishingText(e.target.value)} />
            </Form.Group>
      </Row>
  </Form>
</Fragment>;
}


