import React, { Component } from 'react';
import { Symbols } from 'twm';
import { Modal, Button, InputGroup, FormControl, Row, Col, Card } from 'react-bootstrap';

export default class ConfirmationModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Modal show={true} onHide={this.props.onNo} centered>
              <Modal.Header closeButton>
                <Modal.Title>{this.props.title}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p>{this.props.content}</p>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onNo}>No</Button>
                <Button variant="primary"   onClick={this.props.onYes}>Yes</Button>
              </Modal.Footer>
            </Modal>);
    }
}

