import React, { Component, useState } from 'react'
import { Conversation, ConversationText } from 'twm'
import * as d3 from 'd3';
import _ from 'lodash';
import SearchImages from './SearchImages'
import Modal from 'react-modal';
import './ConversationEditor.css';

import { Badge, 
    Alert, 
    Container, 
    Row,
    Col,
    Card, 
    FormControl,
    Button, 
    ButtonGroup,
    ButtonToolbar, 
    InputGroup } from 'react-bootstrap';

import {Form, Image, Dropdown, DropdownButton } from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import { useConversationEditor } from './hooks';
import { ConversationV5 } from 'twm';
const shortid = require('shortid');


//-------------------------------------------------------------------------
//
//-------------------------------------------------------------------------
export default function TreeView ({conversation, 
    activeSentence,
    onNodeSelected}) {

    const root = d3.hierarchy(conversation);
    var treeLayout = d3.tree();
    treeLayout.size([200, 400]);
    treeLayout(root);

    return <svg height={500} width={400}>
        <g transform='translate(0,20)'>
          {root.links().map(({ source, target, i }) => {
            return (
              <line
                key={shortid.generate()}
                x1={source.x}
                y1={source.y}
                d='M5 20 l215 0'
                x2={target.x}
                y2={target.y}
                stroke={'#4285F4'}
                strokeWidth={1}
              />
            )
          })}

          {root.descendants().map(({ x, y, data }) => {
            return (
                <circle
                key={shortid.generate()}
                cx={x}
                cy={y}
                r={8}
                fill={data.id === activeSentence ? '#2BBBAD' : '#4285F4'}
                onClick={() => onNodeSelected(data)}
                / >
            )
          })}
        </g>
      </svg>;
}


