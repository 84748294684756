import { Conversation, ConversationV5, ConversationText } from 'twm'
import * as d3 from 'd3';
import _ from 'lodash';
const shortid = require('shortid');

// for converging the choices, branches need to
// join with |
//----------------------------------------------------------------
//
//----------------------------------------------------------------
export async function save(publish, convInfo, treeConv) {
      try {
        const imageUriParts = convInfo.imageUri.split('/');
        let conversation =  await Conversation.create(
              convInfo.name.trim(),
              "",
              "",
              imageUriParts[imageUriParts.length - 2] + "/" + imageUriParts[imageUriParts.length - 1],
              convInfo.categories);

        let added = toConversation(conversation, _.cloneDeep(treeConv), null, null)
        if (convInfo.id) {
              added.conversation.id = convInfo.id;
        }

        added.conversation.level = convInfo.level;
        added.conversation.lftags = convInfo.lftags;
        added.conversation.text = convInfo.text;
        added.conversation.version = 5.0;
        added.conversation.finishingText = convInfo.finishingText;
         
        if (convInfo.preStored) {
              added.conversation.preStored = convInfo.preStored;
        }
 
        if (convInfo.created) {
              added.conversation.created = convInfo.created;
        }

        added.conversation.published = publish;

        let saved = await ConversationV5.save(added.conversation);
        const tree = conversationToTree(saved);
        alert('Conversation ' + (publish ? 'published ' : 'saved ') + saved.id)
        return {
                conversation: _.cloneDeep(saved), 
                treeConversation: _.cloneDeep(tree),
                error : null
        };
      } catch (error) {
          alert('Conversation save failed!: '+ error.message);
          let errorMsg = "Unexpected Error!"; 
          if (error.message.includes('409')) {
              errorMsg = "Already Exists";
          } else {
              errorMsg = error.message;
          }
          return ({error: "Failed to save:  " + errorMsg});
      }
}


//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function conversationTextToTree(text) {
    const nText = text.replace(/[\u2019]/g,'\'');
    return ConversationText.build(nText.trim());
}
    
//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function conversationToTree(conv) {
    const tree = toTree(conv.dialogue, 0, null, {});
    return tree;
}

//get the sentence chunks
//for choices add to choices list as ##index <choice text>
//for children for branch outs send the branchout (##index <choice text>
//combine the sentence chunks by space to set sentence
//----------------------------------------------------------------
//
//----------------------------------------------------------------
function toTree(dialogue, index, parent, nodeMap) {
        if (nodeMap[index]) {
            //nodeMap[index].parents.push(parent);
            return nodeMap[index];
        }

        const sentence = dialogue[index].sentence;
        let sentenceMarkChoice = sentence.map(c => c.choices ? {choices : c.choices.map(ch => ({...ch, choice: true}))} : c );
        sentenceMarkChoice = sentenceMarkChoice.map(c => c.multipleChoice ? {multipleChoice : c.multipleChoice.map(ch => ({...ch, choice: true}))} : c );

        const chunks = _.flatten(sentenceMarkChoice.map(x => x.choices  ? x.choices : (x.multipleChoice ? x.multipleChoice : x)));
        const sentenceFromChunks = (chunks.map((s, i) => 
            s.text + ((s.choice && chunks[i + 1] && chunks[i + 1].choice) ? "," : ""))).join(' ');
        
        const choices = _.flatten(sentence.filter(x => x.choices)
            .map(x => x.choices));
        const choiceList = choices.map((c, i) => '##'+ (i + 1) + ' ' + c.text);

        let treeNode = {
            id: index,
            chunks,
            sentence : sentenceFromChunks,
            parents: parent ? [parent] : [],
            choices: choiceList,
            children: [],
            opinion: dialogue[index].opinion,
            ok: true
        };
        nodeMap[index] = treeNode;

        if (parent) {
            parent.children.push(treeNode);
        }
        
        if (dialogue[index].next == null) {
            return treeNode;
        }

        let indices = [];
        if (typeof dialogue[index].next == 'object') {
            //match the keys with choices to get the text
            indices =  choices.map((c, i) => ({
                index: dialogue[index].next[c.symbolId], 
                branchOut: choiceList[i]
            }));

            //Set choices grouping by index
            const choiceGroups = [];
            indices.forEach(i => choiceGroups[i.index - 1] ? 
                choiceGroups[i.index - 1].push(i.branchOut) : 
                (choiceGroups[i.index - 1] = [i.branchOut]));

            treeNode.choices = choiceGroups.filter(v => !!v);
        } else {
            indices = [{index: dialogue[index].next, branchOut: null}];
        }

        indices.forEach(i => toTree(dialogue, i.index , treeNode, nodeMap));
        return treeNode;
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
function joinBranches (conv, index, choices, choiceIndex) {
    const reducer = (a, c) => [
      ...a,
      c.length + (a.length ? a[a.length - 1] : 0)
    ]
    let branchCounts = choices.reduce(reducer, [])
    branchCounts.splice(branchCounts.length - 1, 1)
    branchCounts = [0, ...branchCounts]
    const branches = choices[choiceIndex].map(
      (c, i) =>
        ConversationV5.getBranches(conv, index)[branchCounts[choiceIndex] + i]
    )
    return branches.join('|')
  }

// for converging the choices, branches need to
//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function toConversation (conv, tree, parent, branch) {
    let added = Conversation.addSentence(
      conv,
      tree.chunks,
      parent,
      branch,
      tree.opinion,
      tree.choices || []
    );
    let current = added.index;
    if (tree.children.length) {
        added = tree.children.reduce((a, c, i) => {
        const branches = (tree.choices && tree.choices.length)
          ? joinBranches(a.conversation, current, tree.choices, i)
          : null;
        return toConversation (a.conversation, c, current, branches);
      }, added);
    }
    return added;
}


//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function toList (c, childIndex, choices) {
    if (childIndex >= 0 && choices) {
      c.branch = choices[childIndex];
    }
    const l = [
      c,
      ..._.flatten(c.children.map((ch, i) => toList(ch, i, c.choices)))
    ];
    return l;
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function allSentences(conv, slist) {
    let found = null
    if (!slist) {
        slist = [conv];
    }
    conv.children.forEach(c => {
        slist.push(c);
        slist = allSentences(c, slist);
    });
    return slist;
}


//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function findSentence (conv, matcher) {
    let found = null
    if (matcher(conv)) {
      return conv
    } else {
      conv.children.forEach(c => {
        let childfound = findSentence(c, matcher)
        if (childfound) {
          found = childfound
        }
      })
    }
    return found
}

// to the children add new tree node with empty text 
/*
*  non choice case:
*  - push data as child of parent
  - push the child of parent as child of data 
*
*/
export async function nodeInsert(data, conv) {
   let newConv = _.cloneDeep(conv);
   try {
      const active = findSentence(newConv, c => {
        return c.id === data.id;
      });

      let treeNode = {};      
      const choices = active.chunks.filter(a => a.choice == true);
      if (!choices || choices.length == 0 || (active.choices.length == 0 && active.children.length > 0)) {
            treeNode = {
                id: shortid.generate(),
                chunks: [],
                sentence : '',
                parents: [active],
                choices: [],
                children: [...active.children],
                ok: false
             };
                
             active.children.forEach(c => {
                c.parents = [treeNode];          
             });

             active.children = [treeNode];

      } else {
           treeNode = {
                id: shortid.generate(),
                chunks: [],
                sentence : '',
                parents: [active],
                choices: [],
                children: [],
                ok: false
             };

             active.children.push(treeNode); 
             const choicesL = (active.choices.length);
             active.choices.push(["##" + (choicesL + 1) + ' ' + choices[choicesL].text]);
      }
      
      return newConv;
    } catch (error) {     
      return ({error : "Error while node addition : " + error.message});
    }
    return null;
}

  //remove children
  //find id in parent's children
  //remove it
//----------------------------------------------------------------
//
//----------------------------------------------------------------
export  async function nodeRemove(data, conv, rechunk = false) {
   try {
      let newConv = _.cloneDeep(conv);
      const active = findSentence(newConv, c => {
        return c.id === data.id
      });

      console.log(active, active.children.length == 1 && active.parents.length == 1);
      if (active.children.length == 1 && active.parents.length == 1) {
          const ch = active.parents[0];
          active.children[0].parents[0] = ch;
          active.parents[0].children.push(active.children[0]);
          active.parents[0].children = 
              active.parents[0].children.filter(c => c.id != data.id );
      } 

      return newConv;
    } catch (error) {     
      return ({error : "Error while node removal : " + error.message});
    }
 
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export  async function nodeRemoveAll(data, conv, rechunk = false) {
   try {
      let newConv = _.cloneDeep(conv);
      const active = findSentence(newConv, c => {
        return c.id === data.id
      });

       if (!!active.parents && active.parents.length) {
          const ch = active.parents[0];
          active.parents[0].children = 
              active.parents[0].children.filter(c => c.id != data.id );
      } 

      return newConv;
    } catch (error) {     
      return ({error : "Error while node removal : " + error.message});
    }
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export async function loadNodeChunks (data, conv, rechunk = false) {
    try {
          let newConv = _.cloneDeep(conv);
          const active = getActiveSentence(newConv, data.id);

          if (rechunk || !active.chunks) {
            active.chunks = await Conversation.sentenceChunks(active.sentence);
          }
          return newConv;    
    } catch (error) {     
      return ({error : "Error while fetching chunks: " + error.message});
    }
}

  
//----------------------------------------------------------------
//
//----------------------------------------------------------------
function validateChunks(sentence) {
      if (!sentence || !sentence.chunks) return false;
      const sentenceFromChunks = (sentence.chunks.map((s, i) => s.text + ((s.choice 
          && sentence.chunks[i + 1] && sentence.chunks[i + 1].choice) ? "," : ""))).join(' ');

      if (sentence.sentence !== sentenceFromChunks) {
          return ({error: "Sentence and Chunks don't match! " + sentence.sentence + " != " + sentenceFromChunks});
      }

      const choices = _.flatten(sentence.choices).map(c => {
          const chs = c.split(' ');
          return chs.slice(1, chs.length).join(' ');
      }).join(',');
      const choicesFromChunks = sentence.chunks.reduce((a, v) => {
          v.choice &&  a.push(v.text); 
          return a;
      },[]).join(','); 
      if (choices.length > 0 && choices != choicesFromChunks) {
          return ({errorMsg: "Choice Don't match! " + choices + " : " + choicesFromChunks});
      }

      const choicegroups = sentence.chunks.reduce((a, v, i) => (v.choice && 
          (!sentence.chunks[i - 1] || !sentence.chunks[i - 1].choice)
          && [...a, true]) || a, []);
      if (choicegroups.length > 1) {
          return ({error: "Multiple Choice Groups!"});
      }
  
      return true;
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function validateSentence(isent, conv, forceko) {
  const sent = getActiveSentence(conv, isent.id);
  const val = validateChunks(sent);
  if (!sent.ok && !val.error) {
      sent.ok = true;
  } else {
      sent.ok = false;
  }
  if (forceko == true) {
      sent.ok = false;
  }
  return val;  
}
 
//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function getActiveChunks (conv, activeId) {
    const active = findSentence(conv, c => {
      return c.id === activeId;
    });

    if (!active) {
      return [];
    }

    return active.chunks ? active.chunks : [];
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function updateOpinion (sent, conv, value) {
    const active = getActiveSentence(conv, sent.id);
    active.opinion = value;
    return validateSentence(active, conv);
}


//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function updateChunkText (i, conv, sent, value) {
    const active = getActiveSentence(conv, sent);
    active.chunks[i].text = value;
    return validateSentence(active, conv);
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function getActiveSentence(conv, sent) {
    return findSentence(conv, c => {
      return c.id === sent;
    });
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function setCorrectChoice (i, conv, sent) {
    const active = getActiveSentence(conv, sent);

    active.chunks.forEach(c => {
        c.correct = false;
    });
    active.chunks[i].correct = true;
    return validateSentence(active, conv, true);
}


//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function removeCorrectChoice (i, conv, sent) {
    const active = getActiveSentence(conv, sent);

    active.chunks.forEach(c => {
        c.correct = false;
    });
    return validateSentence(active, conv, true);
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function removeChoice (i, conv, sent) {
    const active = getActiveSentence(conv, sent);

    if ((active.chunks[i - 1] && !active.chunks[i - 1].choice) 
          || i === active.chunks.length - 1 || i === 0) {
      active.chunks[i].choice = false;
    } else if (active.chunks[i + 1] && !active.chunks[i + 1].choice) {
      active.chunks[i].choice = false;
    }
     
    return validateSentence(active, conv);
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function addChoice (i, conv, sent) {
    const active = getActiveSentence(conv, sent);
    
    if (active.chunks[i - 1] && active.chunks[i - 1].choice) {
      active.chunks[i].choice = true
    } else if (active.chunks[i + 1] && active.chunks[i + 1].choice) {
      active.chunks[i].choice = true
    }

    return validateSentence(active, conv);
}
  
//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function duplicateSymbol (i, conv, sent) {
    const active = getActiveSentence(conv, sent);
    active.chunks.splice(i, 0, _.cloneDeep(active.chunks[i]));
    return validateSentence(active, conv);
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function removeSymbol (i, conv, sent) {
    const active = getActiveSentence(conv, sent);
    active.chunks.splice(i, 1)
    return validateSentence(active, conv);
}

//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function updateImage (data, conv, sent, chunk) {
    const active = getActiveSentence(conv, sent);
    active.chunks[chunk].symbolId = data.symbolId;
}


//----------------------------------------------------------------
//
//----------------------------------------------------------------
export function getActiveSymbolText(conv, sent, chunk) {
    const active = getActiveSentence(conv, sent);
    return active.chunks[chunk].text; 
}


