import React, { Component, useState } from 'react'
import { Conversation, ConversationText } from 'twm'
import * as d3 from 'd3';
import _ from 'lodash';
import SearchImages from './SearchImages'
import Modal from 'react-modal';
import './ConversationEditor.css';
import { Badge, 
    Alert, 
    Container, 
    Row,
    Col,
    Card, 
    FormControl,
    Button, 
    ButtonGroup,
    ButtonToolbar, 
    InputGroup } from 'react-bootstrap';

import {Form, Image, Dropdown, DropdownButton } from 'react-bootstrap';
import {Typeahead} from 'react-bootstrap-typeahead';
import { useConversationEditor } from './hooks';
import { ConversationV5 } from 'twm';
import {allSentences, getActiveChunks, getActiveSentence } from './conversationTree';
const shortid = require('shortid');


function SCard({chunk, sentence}) {
    return <Card className={"float-right"} style={{ width: '8em', marginBottom:"0.5em"}}>
                <Card.Img key={shortid.generate()} variant="top" 
                    alt={chunk.text}
                    src={'https://s3-ap-southeast-2.amazonaws.com/twm-data/smart_images_new/' + chunk.symbolId.replace(' ', '+')} />
                <Card.Body>
                  <Card.Text>{chunk.text}</Card.Text>
                  <ButtonToolbar aria-label="Toolbar with button groups" style={{marginLeft: "-0.88em"}}>
                      <ButtonGroup className="mr-2" aria-label="First group">
                          {chunk.choice ?  <Button disabled={true} 
                              variant={sentence.opinion == true ? "success" : "primary"} size="sm">C</Button> :
                              <Button variant="light" 
                                      disabled={true}
                                      size="sm">C</Button>}
                          {chunk.choice && sentence.opinion == true ?
                                  (chunk.correct ?
                              <Button variant="primary" size="sm" disabled={true}>A</Button> :
                              <Button variant="light" size="sm"  disabled={true}>A</Button>) : null}
     
                      </ButtonGroup>
                </ButtonToolbar>

                </Card.Body>
     </Card>
}

export default function ChunksPreview({conversation}) {
    const [imagePicker, setImagePicker] = useState(null);
    const sents = allSentences(conversation); 
    return <React.Fragment>
        {sents.map((s, i) => <Row key={'sent' + i} noGutters={false}>{s.chunks && s.chunks.map((chunk, index) => {
            return <Col key={"chunk_" + index} xs={2} key={"card_" + index}>
                <SCard chunk={chunk} sentence={s}/>
          </Col> ;
        })}</Row>)}
   </React.Fragment>
}

